
<template>
  <div style="overflow: hidden;">
    <div style="transform: rotate(180deg) scale(2, 1); margin-top: 1px;" class="separator-foot">
        <svg viewBox="0 0 1600 100" class="">
            <path d="M0 0v64c35 13 59 31 125 31 104 0 104-44 209-44s105 44 209 44 105-44 209-44 105 44 209 44 105-44 209-44 106 44 209 44 105-44 209-44h12V0z" class="step1"></path>
            <path
                d="M610 77c39 0 39-25 77-25s38 25 76 25 39-25 77-25 38 25 76 25 39-25 77-25 38 25 76 25 39-25 77-25 38 25 76 25 38-25 77-25 38 25 76 25 38-25 76-25 39 25 77 25c32 0 37-18 62-24V0H15v77h9c36 0 37-25 73-25s37 25 74 25 36-25 73-25 37 25 73 25 37-25 74-25 36 25 73 25 37-25 73-25 37 25 74 25"
                class="step2"
            ></path>
            <path
                d="M0 0v69c25-12 37-32 80-32 60 0 60 40 120 40s60-40 120-40 60 40 120 40 60-40 120-40 60 40 120 40 60-40 120-40 61 40 120 40 60-40 120-40 60 40 120 40 60-40 120-40 60 40 120 40 60-40 120-40c42 0 55 20 80 32V0z"
                class="step3"
            ></path>
        </svg>
    </div>
    <footer class="footer" style="padding: 0px; padding-bottom: 15px; color: #fafafa !important; background-color: var(--main-color-bg) !important;">
        <div class="columns" style="vertical-align: middle !important; padding: 15px 15px 10px 15px;">
            <div class="column is-full" style="vertical-align: middle !important; display:flex;align-items: center;">
              <img :title="'Click Me...' + (ignoreTy >= 1 ? ' again (like idk a lot... Keep pressing)': '') " @click="adminClick()" src="https://cdn.gracebot.net/grace/logo.png" alt="gracebot.net" width="82" style="margin-right: 15px; cursor: pointer;" />
              <div id="dawdy4yg" style="display: flex; text-align: end; width: 100%;">
                <router-link style="margin-right: 15px; cursor: pointer;" tag="p" :to="{ path: '/' }" class="is-size-4 mainTextColor has-text-weight-bold">Grace</router-link>
                <router-link style="margin-right: 15px;" class="cg is-size-4" tag="p" :to="{ path: '/vote?ref=gracebot-footer' }">{{ $t("footer.vote") }}</router-link>
                <router-link style="margin-right: 15px;" class="cg is-size-4" tag="p" :to="{ path: '/invite' }">{{ $t("footer.invite") }}</router-link>
                <router-link style="margin-right: 15px;" class="cg is-size-4" tag="p" :to="{ path: '/premium' }">{{ $t("footer.premium") }}</router-link>
                <router-link style="margin-right: 15px;" class="cg is-size-4" tag="p" :to="{ path: '/donors' }">{{ $t("footer.donors") }}</router-link>
                <router-link style="margin-right: 15px;" class="cg is-size-4" tag="p" :to="{ path: '/commands' }">Commands</router-link>
                <!-- <router-link style="margin-right: 15px; display: none !important;" class="cg is-size-4" tag="p" :to="{ path: '/tos' }">Terms Of Service</router-link> -->
                <router-link style="margin-right: 15px;" class="cg is-size-4" tag="p" :to="{ path: '/privacy' }">{{ $t("footer.pp") }}</router-link>
              </div>
            </div>
        </div>
        <div class="columns" id="buwd85" style="vertical-align: middle !important; padding: 15px 15px 10px 15px;">
            <div class="column is-two-thirds" style="align-self: center;vertical-align: middle !important;margin-bottom:15px;">
                <a href="/support" class="ico"><b-icon icon="discord" pack="fab" size="is-large" type="is-black" style="margin-left: 5px;" custom-class="discordBlue" /></a>
                <a :href="'//www.dmca.com/Protection/Status.aspx?ID=82492fe6-a491-4d20-83ea-dd9c03f107e4&refurl=' + this.loc + this.$router.currentRoute.fullPath" title="DMCA.com Protection Status" class="dmca-badge" style="margin-left: 5px;">
                    <img width=46 class="ico" style="vertical-align: sub;" src ="https://images.dmca.com/Badges/DMCA_badge_trn_60w.png?ID=82492fe6-a491-4d20-83ea-dd9c03f107e4" alt="DMCA.com Protection Status" />
                </a>
                <b-button style="margin-left: 10px; margin-top: 5px;" v-if="this.$store.getters.admin.showButton" type="is-info" @click="$store.dispatch('openDebugConsole')">Debug Console</b-button>
                <b-field grouped>
                  <b-field>
                    <template #label>
                      Pick Theme:
                      <b-tag type="is-danger">BETA</b-tag>
                    </template>
                    <b-select :value="$store.getters.admin.theme" @input="this.updateTheme">
                      <option
                        v-for="theme in $store.getters.admin.themes"
                        :value="theme.name"
                        :key="theme.name">
                        {{ (theme.name[0].toUpperCase() + theme.name.substring(1)).split(/(?=[A-Z])/).join(" ")}}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field>
                    <template #label>
                      Pick Language:
                      <b-tag type="is-danger">BETA</b-tag>
                    </template>
                    <b-select :value="$store.getters.admin.locale" @input="this.updateLang">
                      <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ talk.of(locale) ? talk.of(locale) : locale }}</option>
                    </b-select>
                  </b-field>
                </b-field>
                <p style="vertical-align: middle !important;">{{ $t("footer.copyright", [new Date().getFullYear()]) }}</p>
                <p style="vertical-align: middle !important;" v-html='$t("footer.affiliated", ["<a class=\"mainTextColor has-text-weight-bold\" href=\"https://primedhosting.com/aff.php?aff=6&ref=GraceBot\">Primed Hosting</a>"])'></p>
                <p style="vertical-align: middle !important;">{{ $t('footer.notAff') }}</p>
            </div>
            <div class="column has-text-right" id="h48dt5" v-if="ignoreTy >= 1">
                <a href="/support" style="visibility: hidden !important;" class="ico"><b-icon icon="discord" pack="fab" size="is-large" type="is-black" style="margin-left: 5px;" /></a>
                <p>{{ $t('footer.api', [$store.getters.api.version]) }}</p>
                <p>{{ $t('footer.system', [$store.getters.api.SysVersion]) }}</p>
                <p>{{ $t('footer.systemName', [$store.getters.api.hostname]) }}</p>
                <p>{{ $t('footer.avgTime', [($store.getters.api.apiTimesData.length > 0 ? Math.round($store.getters.api.apiTimesData.map(g => g.endTimeSec).reduce((a, b) => a + b, 0) / $store.getters.api.apiTimesData.length) + 's' : "None ATM")]) }}</p>
                <p>Site Version: {{$store.getters.api.packageVersion}}</p>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    props: {},
    data () {
      return {
        loc: '',
        ignoreTy: Number(localStorage.getItem('debugSYS')),
        talk: new Intl.DisplayNames(['en'], { type: 'language' })
      }
    },
    components: {},
    watch: {
      async '$route' (to) {
        this.loc = document.location.origin
      },
      async '$store.getters.user' (data) {
        try {
          if (!data.language) return
          this.$store.commit('themeLang', data.language || 'en')
          this.$root.$i18n.locale = data.language || 'en'
        } catch (e) {}
      }
    },
    methods: {
      adminClick () {
        let numb = Number(localStorage.getItem('debugSYS'))
        numb++
        console.warn(`An Click of the debugSYS button happened... Now at ${numb}`)
        localStorage.setItem('debugSYS', numb)
        this.ignoreTy = numb
        if (numb === 5) this.$store.commit('updateAdminVar', { var: 'showButton', value: true })
        else if (numb === 18) {
          this.$confetti.start()
          setTimeout(() => {
            this.$confetti.stop()
          }, 15000)
        } else if (numb === 69) {
          window.location = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ&getRickRoledBy=GraceBot&YouPressThisIcon69TIMESYourAMadManOrWomanOrIt'
        }
      },
      updateTheme (value) {
        this.$store.commit('themeUpdate', value || 'dark')
      },
      updateLang (value) {
        if (this.$store.getters.user) {
          this.$store.dispatch('patchApi', { path: 'user/profile', body: { action: 'lang', value: (value || 'en') }, headers: {}, params: {} }).then(async (result) => {
            this.$buefy.snackbar.open({
              message: `Updated Language to ${(this.talk.of(value || 'en') ? this.talk.of(value || 'en') : value || 'en')}!<br>Not everything is translated, to help go to our discord!`,
              type: 'is-success',
              duration: 5000
            })
            this.$store.commit('themeLang', value || 'en')
            this.$root.$i18n.locale = value || 'en'
            await this.$store.dispatch('sleep', 2000)
            this.$store.dispatch('getUser')
          }).catch(async (error) => {
            console.log(error, 'Error')
            this.$buefy.snackbar.open({
              message: 'Oh no! Some error happened!',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
            this.$store.commit('themeLang', 'en')
            this.$root.$i18n.locale = 'en'
            await this.$store.dispatch('sleep', 2000)
            this.$store.dispatch('getUser')
          })
        } else {
          this.$store.commit('themeLang', value || 'en')
          this.$root.$i18n.locale = value || 'en'
          this.$buefy.snackbar.open({
            message: `Updated Language to ${(this.talk.of(value || 'en') ? this.talk.of(value || 'en') : value || 'en')}!<br>Not everything is translated, to help go to our discord!`,
            type: 'is-success',
            duration: 5000
          })
        }
      }
    },
    async created () {
      this.loc = document.location.origin
    },
    async mounted () {
      this.$store.commit('themeLang', this.$store.getters.admin.locale || 'en')
      this.$root.$i18n.locale = this.$store.getters.admin.locale || 'en'
    }
  }
</script>

<style>
  .discordBlue {
    color: #5865F2
  }
</style>
<style scoped>

  svg {
      overflow: hidden;
      display: block;
      transform: translateY(-1px);
  }

  .step1 {
      opacity: .25;
      fill: var(--main-color-bg-2);
      -webkit-animation: dividerAnimation3 120s cubic-bezier(.1,0,.9,1) infinite;
      animation: dividerAnimation3 120s cubic-bezier(.1,0,.9,1) infinite;
  }

  .step2 {
    opacity: .5;
    fill: var(--main-color-bg-2);
    -webkit-animation: dividerAnimation2 120s cubic-bezier(.1,0,.9,1) infinite;
    animation: dividerAnimation2 120s cubic-bezier(.1,0,.9,1) infinite;
  }

  .step3 {
    opacity: 1;
    fill: var(--main-color-bg);
    -webkit-animation: dividerAnimation1 120s cubic-bezier(.1,0,.9,1) infinite;
    animation: dividerAnimation1 120s cubic-bezier(.1,0,.9,1) infinite;
  }

  @-webkit-keyframes dividerAnimation1 {
    0% {
        transform: translate(-25%);
    }
    50% {
        transform: translate(25%);
    }
    to {
        transform: translate(-25%);
    }
  }
  @keyframes dividerAnimation1 {
      0% {
          transform: translate(-25%);
      }
      50% {
          transform: translate(25%);
      }
      to {
          transform: translate(-25%);
      }
  }
  @-webkit-keyframes dividerAnimation2 {
      0% {
          transform: translate(18%, 5%) scaleX(1.25);
      }
      50% {
          transform: translate(-18%, 5%) scaleX(1.25);
      }
      to {
          transform: translate(18%, 5%) scaleX(1.25);
      }
  }
  @keyframes dividerAnimation2 {
      0% {
          transform: translate(18%, 5%) scaleX(1.25);
      }
      50% {
          transform: translate(-18%, 5%) scaleX(1.25);
      }
      to {
          transform: translate(18%, 5%) scaleX(1.25);
      }
  }
  @-webkit-keyframes dividerAnimation3 {
      0% {
          transform: translate(-8%, 10%);
      }
      50% {
          transform: translate(8%, 10%);
      }
      to {
          transform: translate(-8%, 10%);
      }
  }
  @keyframes dividerAnimation3 {
      0% {
          transform: translate(-8%, 10%);
      }
      50% {
          transform: translate(8%, 10%);
      }
      to {
          transform: translate(-8%, 10%);
      }
  }

    .has-shadow {
      box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
    }
    @media only screen and (max-width: 768px) {
        #dawdy4yg {
          display: block !important;
        }
        /* #h48dt5 {text-align: center !important;} */

        #buwd85 {
          flex-direction: column-reverse;
          display: flex;
        }
        /* .columns {
            margin-right: 0px !important;
        } */
    }
    .mainTextColor {
        background: var(--is-premium-1);
        -webkit-background-clip: text !important;
        background-clip: text;
        -webkit-text-fill-color: transparent !important;
    }
    .mainTextColor:hover {
        color: #7957d5 !important;
        background-clip: unset;
        -webkit-text-fill-color: unset !important;
    }
    .ico:hover {
        opacity: .5;
    }
    .ico {
        transition: .2s;
    }
    .cg {
        transition: .2s;
        color: #fafafa;
        display: block !important;
        cursor: pointer;
    }
    .cg:hover {
        color: #7957d5
    }
    a {
        transition: .2s;
    }
</style>
