import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store.js'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import axios from './axinos'
import './assets/css/main.css'
import './assets/css/hotFixes.css' // These are hot fixes that will be removed on later release.
import JQuery from 'jquery'
import VueGtag from 'vue-gtag'
import VueConfetti from 'vue-confetti'
// import Swal from 'sweetalert2'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@sweetalert2/theme-dark'
import 'vue-select/dist/vue-select.css'
import FormData from 'form-data'
import MomentJS from 'moment'
import MomentJSFormat from 'moment-duration-format'
import VueLocalStorage from 'vue-localstorage'
import { website as messages } from 'locales'
import VueI18n from 'vue-i18n'
import VueDiscordMessage from 'vue-discord-message'
MomentJSFormat(MomentJS)
// import Ads from 'vue-google-adsense'

// Vue.config.productionTip = false
// Vue.config.devtools = false
// ENABLE TO TEST A SEMI PRODUCTION MODE
Vue.use(VueI18n)
Vue.use(VueDiscordMessage, {
  componentNames: {
    mention: 'd-mention'
  }
})
const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages
})

Vue.use(VueLocalStorage)
Vue.use(Buefy, {
  defaultIconPack: 'fas'
})
Vue.use(require('vue-script2'))
// Vue.use(Ads.Adsense)
// Vue.use(Ads.InArticleAdsense)
// Vue.use(Ads.InFeedAdsense)
Vue.use(VueConfetti)
Vue.prototype.$PrettyMs = require('pretty-ms')
Vue.prototype.$ = JQuery
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.prototype.$log = console.log
Vue.prototype.$swal = Swal
Vue.prototype.$FormData = FormData
Vue.prototype.$MomentJS = MomentJS
Vue.prototype.$window767 = window.matchMedia('(max-width: 767px)')
// window.matchMedia('(max-width: 767px)').onchange = async (data) => {
//   console.log(Vue.prototype.$window767, 'before')
//   Vue.prototype.$window767 = data
//   console.log(Vue.prototype.$window767, 'after')
// }

Vue.prototype.$copyToClipboard = function (Text) {
  var temp = Vue.prototype.$('<input>')
  Vue.prototype.$('body').append(temp)
  temp.val(Text).select()
  document.execCommand('copy')
  temp.remove()
  this.$buefy.snackbar.open({
    message: 'Copied!',
    type: 'is-success',
    position: 'is-bottom-right'
  })
};

(function ($) {
  $.fn.donetyping = function (callback) {
    var _this = $(this)
    var xtimer
    _this.data('val', _this.val())
    _this.keyup(function () {
      clearTimeout(xtimer)
      if (_this.data('val') !== _this.val()) {
        xtimer = setTimeout(cleartimer, 3000)
      }
      waitchange()
    })

    function cleartimer () {
      clearTimeout(xtimer)
      callback.call(_this)
    }

    function waitchange () {
      setTimeout(function () {
        _this.data('val', _this.val())
      }, 1000)
    }
  }
})(Vue.prototype.$)

window.ondragstart = function () { return false }
Vue.use(VueGtag, { config: { id: store.getters.google.id } }, router)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
