module.exports = [
  {
    path: '/',
    alias: '/home',
    meta: {
      title: 'Home'
    },
    sitemap: {
      priority: 0.9
    },
    component: () => import('../views/home.vue')
  },
  {
    path: '/premium',
    alias: ['/buy', '/store', '/buy/', '/store/', '/upgrade', '/upgrade/'],
    meta: {
      title: 'Premium'
    },
    sitemap: {
      priority: 0.8,
      changefreq: 'weekly'
    },
    component: () => import('../views/premium.vue')
  },
  {
    path: '/donors',
    alias: ['/donor', '/supporters', '/supporter', '/patron', '/patrons'],
    meta: {
      title: 'Our Donors'
    },
    sitemap: {
      priority: 0.8,
      changefreq: 'daily'
    },
    component: () => import('../views/donors.vue')
  },
  {
    path: '/privacy',
    alias: ['/privacypolicy', '/pp'],
    meta: {
      title: 'Privacy Policy'
    },
    sitemap: {
      priority: 0.8,
      changefreq: 'monthly'
    },
    component: () => import('../views/privacy.vue')
  },
  {
    path: '/features',
    alias: ['/feature'],
    meta: {
      title: 'Features'
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'weekly'
    },
    component: () => import('../views/features.vue')
  },
  {
    path: '/commands',
    alias: ['/command', '/cmds', '/cmd'],
    meta: {
      title: 'Commands'
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'weekly'
    },
    component: () => import('../views/commands.vue')
  },
  {
    path: '/support',
    meta: {
      title: 'Support'
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'weekly'
    }
  },
  {
    path: '/invite',
    meta: {
      title: 'Invite'
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'weekly'
    }
  },
  {
    path: '/vote',
    meta: {
      title: 'Vote'
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'weekly'
    }
  },
  {
    path: '/docs',
    meta: {
      title: 'Docs'
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'weekly'
    }
  },
  {
    path: '/docs/website',
    meta: {
      title: 'Docs Website'
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'weekly'
    }
  },
  {
    path: '/docs/api',
    meta: {
      title: 'Docs Api'
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'weekly'
    }
  },
  {
    path: '/login',
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/trello',
    meta: {
      title: 'Trello'
    },
    sitemap: {
      priority: 0.7
    }
  },
  {
    path: '*',
    meta: {
      title: '404 Page not found'
    },
    component: () => import('../views/404.vue')
  },
  {
    path: '/500',
    meta: {
      title: '500 Weird Error',
      private: true
    },
    component: () => import('../views/500.vue'),
    alias: ['/500/', '/weirderror', '/weirderror/', '/servercrash', '/servercrash/']
  },
  {
    path: '/redirect',
    meta: {
      title: 'Redirect Page',
      private: true
    },
    component: () => import('../views/redirect.vue'),
    alias: ['/r/', '/changesite', '/re/']
  },
  {
    path: '/callback',
    meta: {
      title: 'Login Callback',
      private: true
    }
  },
  {
    path: '/logout',
    meta: {
      title: 'Logout Page',
      private: true
    },
    component: () => import('../views/logout.vue')
  },
  {
    path: '/dashboard',
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'daily'
    },
    component: () => import('../views/dashboard/selector'),
    alias: '/d'
  },
  {
    path: '/dashboard/:id/:page?',
    meta: {
      title: 'Guild Dashboard',
      requiresAuth: true,
      dashboard: true,
      page: ['main', 'mod', 'log', 'level', 'twitch', 'actions', 'music', 'customize', 'audit', 'reddit'],
      id: '/v1/system/getAllGuilds'
    },
    component: () => import('../views/dashboard/main.vue'),
    alias: ['/dashboard/:id/:page?/', '/d/:id/:page?', '/d/:id/:page?/']
  },
  {
    path: '/leaderboard/:id',
    meta: {
      title: 'Leaderboard',
      requiresAuth: false,
      dashboard: false,
      id: '/v1/system/getAllUsers'
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'always'
    },
    component: () => import('../views/leaderboard.vue'),
    alias: ['/leaderboard/:id/', '/l/:id', '/l/:id/']
  },
  {
    path: '/queue/:id',
    meta: {
      title: 'Queue',
      requiresAuth: false,
      dashboard: false,
      id: '/v1/system/getAllGuilds'
    },
    sitemap: {
      priority: 0.7,
      changefreq: 'always'
    },
    component: () => import('../views/queue.vue'),
    alias: ['/queue/:id/', '/q/:id', '/q/:id/']
  },
  {
    path: '/profile/:id',
    meta: {
      title: 'Profile',
      requiresAuth: false,
      dashboard: false,
      id: '/v1/system/getAllUsers'
    },
    sitemap: {
      priority: 0.6,
      changefreq: 'daily'
    },
    component: () => import('../views/profile.vue'),
    alias: ['/profile/:id/', '/p/:id', '/p/:id/']
  },
  {
    path: '/archive/:id',
    meta: {
      title: 'Archive',
      requiresAuth: false,
      dashboard: false,
      private: true
    },
    component: () => import('../views/archive.vue'),
    alias: ['/arhive/:id/', '/a/:id', '/a/:id/']
  },
  {
    path: '/report',
    meta: {
      title: 'Report',
      requiresAuth: true,
      dashboard: false,
      private: false
    },
    component: () => import('../views/report.vue'),
    alias: ['/r/', '/r']
  },
  {
    path: '/promo',
    meta: {
      title: 'Promotion',
      requiresAuth: true
    },
    component: () => import('../views/promo'),
    alias: ['/redeem', '/codes', '/code']
  },
  {
    path: '/stats',
    meta: {
      title: 'Bot Stats',
      requiresAuth: false,
      dashboard: false
    },
    sitemap: {
      priority: 0.8,
      changefreq: 'hourly'
    },
    component: () => import('../views/stats.vue'),
    alias: ['/botstats', '/botstat', '/botstatus', '/status']
  },
  {
    path: '/patreon/login',
    meta: {
      title: 'Patreon Login',
      requiresAuth: true,
      dashboard: false,
      private: true
    }
  },
  {
    path: '/patreon/delete',
    meta: {
      title: 'Patreon Delete',
      requiresAuth: true,
      dashboard: false,
      private: true
    }
  },
  {
    path: '/serverAdded',
    meta: {
      title: 'Add Server',
      requiresAuth: false,
      dashboard: false,
      private: true
    },
    component: () => import('../views/serverAdded.vue'),
    alias: []
  },
  {
    path: '/lvl-calculator',
    meta: {
      title: 'Level Calculator',
      requiresAuth: false,
      dashboard: false
    },
    component: () => import('../views/lvl-calculator.vue'),
    alias: ['/calculator', '/xpcal', '/level-calculator', '/xp-calculator', '/lvlcal', '/levelcal', '/xpcal']
  }
]
