<template>
  <div id="app">
    <b-notification class="is-centered EMTAlert" v-if="this.$store.getters.admin.streamerMode" :style="'background: -webkit-linear-gradient(200deg,#9656eb,#9656eb,#d64bea,#d64bea) !important;'" type="is-danger" icon="camera" has-icon aria-close-label="Close notification" role="alert">
      You have streamer mode enabled! All important info is hidden! (To disable do ?streamerMode=false in your url to disable)!
    </b-notification>
    <NavBar />
    <div style="min-height: 100vh; min-width:100vw;margin-bottom: 50px;">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<style >
  @media only screen and (max-width: 768px) {
    .EMTAlert {
      margin-left: unset !important;
      margin-right: unset !important;
      margin-top: unset !important;
    }
  }
  .EMTAlert {
    margin-bottom: 0px !important;
    text-align: center !important;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    z-index: 100 !important;
  }
</style>

<script>
  import NavBar from '@/components/NavBar.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    data () {
      return {
        // key: 97,
        resizeId: null
      }
    },
    async created () {
      if (!this.$store.getters.authToken) this.$store.commit('authToken', '')
      if (!this.$localStorage.get('notificationsClosed')) this.$localStorage.set('notificationsClosed', JSON.stringify([]))
      this.$store.dispatch('init').then(async () => {
        await this.$store.dispatch('sleep', 2000)
        if (!this.$store.getters.user) return
        this.$root.$i18n.locale = this.$store.getters.user.language
      })
    },
    async mounted () {
      await this.$store.dispatch('sleep', 1000)
      if (this.$route.query.theme) this.$store.commit('themeUpdate', (this.$store.getters.admin.themes.find(g => g.name.toLowerCase() === this.$route.query.theme) ? this.$store.getters.admin.themes.find(g => g.name.toLowerCase() === this.$route.query.theme).name : 'dark') || 'dark')
      else this.$store.commit('themeUpdate', this.$store.getters.admin.theme || 'dark')
    },
    beforeDestroy () {
    },
    watch: {
    },
    components: {
      NavBar,
      Footer
    },
    methods: {
    }
  }
</script>
