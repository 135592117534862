
<template>
  <b-message @close="closedNote(('not-' + notification.id))" v-if="notification" :type="'is-' + notification.type" has-icon :title="this.$store.getters._momentFormatUnix(notification.date)" :id="('not-' + notification.id)" aria-close-label="Close message">
    <div v-html="notification.message"></div>
  </b-message>
</template>

<script>
  export default {
    name: 'Notification',
    props: {
      notification: Object
    },
    methods: {
      closedNote (not) {
        return this.$parent.$parent.$parent.closedNote(not)
      }
    }
  }
</script>
