import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store.js'

import routes from './paths'

Vue.use(VueRouter)

const routeCodes = [
  {
    path: '/support',
    beforeEnter (to, from, next) {
      window.location = store.getters.admin.discord.url + 'invite/9cGQkkf'
    }
  },
  {
    path: '/invite',
    beforeEnter (to, from, next) {
      window.location = (store.getters.admin.discord.url + 'oauth2/authorize?client_id=604170962178408469&scope=bot+applications.commands&permissions=' + (to.query.isAdmin ? '8589934591' : '8589934583') + '&response_type=code&redirect_uri=https://gracebot.net/serverAdded&guild_id=' + to.query.guild_id)
    }
  },
  {
    path: '/vote',
    beforeEnter (to, from, next) {
      window.location = 'https://top.gg/bot/604170962178408469/vote?' + Object.keys(to.query).map(key => `${key}=${to.query[key]}`).join('&')
    }
  },
  {
    path: '/status',
    beforeEnter (to, from, next) {
      window.location = 'https://status.gracebot.net/'
    }
  },
  {
    path: '/docs',
    beforeEnter (to, from, next) {
      window.open('https://docs.gracebot.net/', '_blank')
      next('/?ref=docs')
    }
  },
  {
    path: '/docs/website',
    beforeEnter (to, from, next) {
      window.open('https://docs.gracebot.net/v/website/', '_blank')
      next('/?ref=docs')
    }
  },
  {
    path: '/docs/api',
    beforeEnter (to, from, next) {
      window.open('https://docs.gracebot.net/v/api/', '_blank')
      next('/?ref=docs')
    }
  },
  {
    path: '/login',
    beforeEnter (to, from, next) {
      console.log(to)
      sessionStorage.setItem('login-redirect', to.query.redirect || from.fullPath || '/')
      window.location = `${store.getters.admin.discord.url}oauth2/authorize?client_id=604170962178408469&redirect_uri=${encodeURIComponent((process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://api.gracebot.net/') + 'v1/auth/callback')}&response_type=code&scope=identify+guilds+email&prompt=none`
    }
  },
  {
    path: '/trello',
    beforeEnter (to, from, next) {
      window.location = 'https://trello.com/b/07SsU5zN/grace-bot'
    }
  },
  {
    path: '/redirect',
    beforeEnter (to, from, next) {
      if (!to.query.to || !validURL(to.query.to)) return next('/')
      const link = new URL(to.query.to)
      if ((link.host || link.hostname) === 'gracebot.net') return next(link.pathname)
      next()
      function validURL (str) { var pattern = new RegExp('^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.) {3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$', 'i'); return !!pattern.test(str) }
    }
  },
  {
    path: '/callback',
    async beforeEnter (to, from, next) {
      if (to.query.token) {
        await store.commit('authToken', to.query.token)
      } else if (to.query.goodbye) {
        await store.commit('authToken', '')
      } else {
        await store.commit('authToken', '')
      }
      await store.dispatch('sleep', 2000)
      if (sessionStorage.getItem('login-redirect')) {
        next({ path: sessionStorage.getItem('login-redirect'), query: { ref: 'login' } })
        sessionStorage.removeItem('login-redirect')
      } else return next({ path: '/', query: { ref: 'login' } })
    }
  },
  {
    path: '/dasboard',
    beforeEnter (to, from, next) {
      if (to.query.token) store.commit('authToken', to.query.token)
      next()
    }
  },
  {
    path: '/dashboard/:id/:page?',
    beforeEnter (to, from, next) {
      if (to.query.token) store.commit('authToken', to.query.token)
      next()
    }
  },
  {
    path: '/promo',
    beforeEnter (to, from, next) {
      if (to.query.token) store.commit('authToken', to.query.token)
      next()
    }
  },
  {
    path: '/patreon/login',
    async beforeEnter (to, from, next) {
      window.location = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${store.getters.patreon.id}&redirect_uri=${encodeURIComponent((process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://api.gracebot.net/') + 'v1/plugin/patreon/oauth')}&state=gracetoken=${store.getters.authToken},goto=site&scope=${encodeURIComponent('identity identity[email] identity.memberships campaigns campaigns.members')}`
    }
  },
  {
    path: '/patreon/delete',
    async beforeEnter (to, from, next) {
      window.location = (process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://api.gracebot.net/') + 'v1/plugin/patreon/delete?token=' + store.getters.authToken
    }
  }
]
routes.forEach(async (item, index) => {
  if (routeCodes.find(g => g.path === item.path)) {
    routes[index] = { ...item, ...routeCodes.find(g => g.path === item.path) }
  }
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  watch: {
    '$route' (to, from) {
      document.title = 'Grace Bot • ' + to.meta.title || 'Grace Bot'
    }
  },
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.query.ref === 'topgg') store.commit('updateAdminVar', { var: 'topggRestrictions', value: true })
  if (to.query.admin === 'COERig') {
    store.commit('updateAdminVar', { var: 'showButton', value: true })
    localStorage.setItem('debugSYS', (Number(localStorage.getItem('debugSYS') >= 5 ? Number(localStorage.getItem('debugSYS')) : 5)))
  } else if (to.query.streamerMode || to.query.streamerMode === null) {
    if (to.query.streamerMode === 'yes' || to.query.streamerMode === true || to.query.streamerMode === 'true' || to.query.streamerMode === null) {
      store.commit('updateAdminVar', { var: 'streamerMode', value: true })
      localStorage.setItem('streamerMode', true)
    } else {
      store.commit('updateAdminVar', { var: 'streamerMode', value: false })
      localStorage.setItem('streamerMode', false)
    }
  }
  if (store.getters.admin.systemIds[Number(to.query.systemid)]) {
    const systemID = store.getters.admin.systemIds[Number(to.query.systemid)]
    Vue.prototype.$buefy.snackbar.open({
      message: systemID.msg + '<br>' + (systemID.helpURL ? `<a href="${systemID.helpURL}">Click Here for more!</a>` : ''),
      type: 'is-warning',
      position: 'is-bottom-right',
      duration: 35000
    })
  } else if (to.query.errorMessage && to.query.errorCode) {
    Vue.prototype.$buefy.snackbar.open({
      message: `Error Code: ${to.query.errorCode}<br>Error Message: ${decodeURIComponent(to.query.errorMessage).toString()}<br>From: ${(decodeURIComponent(to.query.fromError) ? decodeURIComponent(to.query.fromError).toString() : '').split('-').join(' ')}`,
      type: 'is-danger',
      position: 'is-bottom-right',
      duration: 35000
    })
  }
  function proceed () {
    if (store.getters.isLoaded) {
      if (to.matched.some(record => record.meta.requiresAuth) || to.matched.some(record => record.meta.dashboard)) {
        if (store.getters.isLogged) {
          document.title = to.meta.title + ' • ' + 'Grace Bot'
          // next()
          if (to.matched.some(record => record.meta.dashboard)) {
            to.params.page = (to.params.page ? to.params.page.toLowerCase() : undefined)
            switch (to.params.page) {
            case 'home':
              to.params.page = 'main'
              break
            case 'moderator':
              to.params.page = 'mod'
              break
            case 'logging':
              to.params.page = 'log'
              break
            case 'audit':
              to.params.page = 'audit'
              break
            case 'audits':
              to.params.page = 'audit'
              break
            case 'auditlog':
              to.params.page = 'audit'
              break
            case 'auditlogs':
              to.params.page = 'audit'
              break
            case 'action':
              to.params.page = 'actions'
              break
            case 'customaction':
              to.params.page = 'actions'
              break
            case 'customactions':
              to.params.page = 'actions'
              break
            case 'customizebot':
              to.params.page = 'customize'
              break
            case undefined:
              to.params.page = 'main'
              break
            case null:
              to.params.page = 'main'
              break
            }
            if (['main', 'mod', 'log', 'level', 'twitch', 'actions', 'music', 'customize', 'audit', 'reddit'].indexOf(to.params.page.toLowerCase()) === -1) return next('/404')
            next()
            return
          }
          next()
          return
        }
        next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
      } else {
        document.title = to.meta.title + ' • ' + 'Grace Bot'
        next()
      }
    } else {
      document.title = to.meta.title + ' • ' + 'Grace Bot'
      next()
    }
  }
  // store.dispatch('init')
  if (!store.getters.isLoaded && to.matched.some(record => record.meta.requiresAuth)) {
    store.watch(
      (state) => store.getters.isLoaded,
      (value) => {
        if (value === true) {
          proceed()
        }
      }
    )
  } else {
    proceed()
  }
})

export default router
