import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: {
      version: 1,
      path: (process.env.VUE_APP_BUILDSET === 'ProDev' ? 'https://api.gracebot.net/' : (process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://api.gracebot.net/')),
      SysVersion: '0',
      hostname: '',
      apiTimesData: [],
      packageVersion: process.env.VUE_APP_PKGVER || '0'
    },
    user: null,
    isLogged: false,
    isLoggingOut: false,
    authToken: localStorage.getItem('token') || '',
    notifications: [],
    build: (process.env.NODE_ENV === 'development' || process.env.VUE_APP_BUILDSET === 'ProDev' ? 'development' : 'production'),
    isLoaded: false,
    guilds: [],
    morning: (new Date().getHours() < 12),
    UI: localStorage.getItem('UI') ? (localStorage.getItem('UI').toLowerCase() !== 'old' && localStorage.getItem('UI').toLowerCase() ? 'new' : localStorage.getItem('UI').toLowerCase()) : 'new',
    google: {
      id: 'UA-146115140-1',
      adClient: 'ca-pub-9068126173089791',
      dashboardSideBar: '9368725610',
      dashboardSelector: '5306492993',
      profile: '4115333270',
      dashboardMobile: '4196908536',
      showAds: true,
      recapPub: '6LdLwucZAAAAAP9kgSAlOV21VrxMXXcowqKMFDm7',
      reason: '<b>Why do we show ads?</b>. <br>The simple answer is to make some money to keep us going.<br>We made sure to place them in a way so your expirence damaged.<br>If you dont want to see ads support us by getting premium!'
    },
    patreon: {
      id: 'VpIiwPkS1kxUxtq3M7wdveIuh-j0ZPyw0dLMRtQbduD1QVcZzzkvB_-dHJ_3Ghux'
    },
    admin: {
      theme: localStorage.getItem('theme') || 'dark',
      themes: [
        {
          default: true,
          name: 'dark',
          premium: false,
          show: true
        },
        {
          default: false,
          name: 'deepDark',
          premium: false,
          show: true
        }
      ],
      locale: localStorage.getItem('lang') || 'en',
      showButton: Number(localStorage.getItem('debugSYS')) >= 5,
      streamerMode: localStorage.getItem('streamerMode') === 'true' || localStorage.getItem('streamerMode') === true || localStorage.getItem('streamerMode') === 'yes',
      discord: {
        cdn: 'https://cdn.discordapp.com/',
        url: 'https://discord.com/'
      },
      topggRestrictions: false,
      rick: false,
      promo: false,
      systemIds: {
        1892: {
          msg: 'Please connect your discord to patreon',
          helpURL: 'https://support.patreon.com/hc/en-us/articles/212052266-Get-my-Discord-role'
        },
        1876: {
          msg: 'You are not subscribed to Premium',
          helpURL: 'https://www.patreon.com/bePatron?u=32847695'
        },
        1578: {
          msg: 'We can\'t find your discord email. Try Relogging.',
          helpURL: null
        },
        1579: {
          msg: 'The email given isnt found in our premium systems. Make sure your membership has the same email as your discord!',
          helpURL: null
        },
        1545: {
          msg: 'You are not subscribed to Premium. (No Active Memberships!)',
          helpURL: 'https://www.buymeacoffee.com/GraceBot'
        },
        1571: {
          msg: 'You already have a linked membership. Please unlink the first one!',
          helpURL: null
        }
      }
    }
  },
  mutations: {
    user (state, data) {
      state.user = data
    },
    themeUpdate (state, data) {
      localStorage.setItem('theme', data)
      state.admin.theme = data
      const htmlElement = document.documentElement
      if (data === 'deepDark') {
        htmlElement.setAttribute('theme', 'deepDark')
      } else {
        htmlElement.setAttribute('theme', 'dark')
      }
    },
    themeLang (state, data) {
      localStorage.setItem('lang', data)
      state.admin.locale = data
    },
    isLogged (state, data) {
      state.isLogged = data
    },
    isLoggingOut (state, data) {
      state.isLoggingOut = data
    },
    authToken (state, data) {
      if (data === '' || !data) localStorage.removeItem('token')
      else localStorage.setItem('token', data)
      state.authToken = data
    },
    notifications (state, data) {
      state.notifications = data
    },
    LoadStatus (state, data) {
      state.isLoaded = data
    },
    setGuilds (state, data) {
      state.guilds = data
    },
    setUI (state, stat) {
      state.UI = stat
    },
    updateAdminVar (state, data = { var: null }) {
      state.admin[data.var] = data.value
      if (data.var === 'showButton' && data.value === true) {
        Vue.prototype.$buefy.snackbar.open({
          message: 'Debug Button Enabled!<br>You can remove code from url if you enabled it that way.',
          type: 'is-success',
          duration: 15000
        })
      }
    },
    updateAPI (state, data = { var: null }) {
      state.api[data.var] = data.value
    },
    updateAdvertisement (state, data = { var: null }) {
      state.advertisement[data.var] = data.value
    },
    updateAPITimes (state, data = { startTime: null, endTime: null, endTimeSec: null, apiURL: null, urlCreation: null, error: null }) {
      data = { startTime: (data.startTime ? data.startTime : null), endTime: (data.endTime ? data.endTime : null), endTimeSec: ((Number(data.endTime) - Number(data.startTime)) / 1000), apiURL: (data.apiURL ? data.apiURL : null), urlCreation: (data.urlCreation ? data.urlCreation : null), error: (data.error ? data.error : null) }
      state.api.apiTimesData.push(data)
      return data
    }
  },
  actions: {
    init ({ commit, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get(getters.api.path + 'version', {
          headers: {
            Authorization: getters.authToken
          }
        }).then((result) => {
          commit('updateAPI', { var: 'version', value: result.data.apiVersion })
          commit('updateAPI', { var: 'SysVersion', value: result.data.SysVersion })
          commit('updateAPI', { var: 'hostname', value: result.data.hostname })
          dispatch('getUser').then(() => {
            resolve()
            dispatch('getNotification').catch(() => { resolve() })
          }).catch(() => { resolve() })
          // .then(() => {
          //   resolve()
          // })
        }).catch(() => { resolve() })
      })
    },
    logout ({ commit, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        if (getters.isLoggingOut) return reject(new Error('Is Logging out already...'))
        if (getters.islogged) return reject(new Error('According to data were not logged in.'))
        commit('isLoggingOut', true)
        Vue.prototype.$http.delete(getters._genApiUrl('auth/logout'), {
          headers: {
            Authorization: getters.authToken
          }
        }).then((result) => {
          commit('authToken', '')
          commit('isLoggingOut', false)
          commit('user', null)
          commit('isLogged', false)
          commit('LoadStatus', false)
          resolve(result)
        }).catch((g) => {
          commit('authToken', '')
          commit('isLoggingOut', false)
          commit('user', null)
          commit('isLogged', false)
          commit('LoadStatus', false)
          reject(g)
        })
      })
    },
    getApi ({ commit, dispatch, getters }, data = { path: '', params: {} }) {
      data = { path: (data.path ? data.path : ''), params: (data.params ? data.params : {}) }
      return new Promise((resolve, reject) => {
        const startTime = new Date()
        Vue.prototype.$http.get(getters._genApiUrl(data.path), {
          headers: {
            Authorization: getters.authToken
          },
          params: data.params
        }).then((result) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(data.path), urlCreation: data, rawData: result })
          resolve(result)
        }).catch((g) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(data.path), urlCreation: data, error: g })
          reject(g)
        })
      })
    },
    postApi ({ commit, dispatch, getters }, data = { path: '', body: '', headers: {}, params: {} }) {
      data = { path: (data.path ? data.path : ''), body: (data.body ? data.body : ''), headers: (data.headers ? data.headers : {}), params: (data.params ? data.params : {}) }
      return new Promise((resolve, reject) => {
        const startTime = new Date()
        Vue.prototype.$http.post(getters._genApiUrl(data.path), data.body, {
          headers: {
            Authorization: getters.authToken,
            ...data.headers
          },
          params: data.params
        }).then((result) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(data.path), urlCreation: data, rawData: result })
          resolve(result)
        }).catch((g) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(data.path), urlCreation: data, error: g })
          reject(g)
        })
      })
    },
    deleteApi ({ commit, dispatch, getters }, data = { path: '', body: '', headers: {}, params: {} }) {
      data = { path: (data.path ? data.path : ''), body: (data.body ? data.body : ''), headers: (data.headers ? data.headers : {}), params: (data.params ? data.params : {}) }
      return new Promise((resolve, reject) => {
        const startTime = new Date()
        Vue.prototype.$http.delete(getters._genApiUrl(data.path), {
          params: data.params,
          data: data.body,
          headers: {
            Authorization: getters.authToken,
            ...data.headers
          }
        }).then((result) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(data.path), urlCreation: data, rawData: result })
          resolve(result)
        }).catch((g) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(data.path), urlCreation: data, error: g })
          reject(g)
        })
      })
    },
    patchApi ({ commit, dispatch, getters }, data = { path: '', body: '', headers: {}, params: {} }) {
      data = { path: (data.path ? data.path : ''), body: (data.body ? data.body : ''), headers: (data.headers ? data.headers : {}), params: (data.params ? data.params : {}) }
      return new Promise((resolve, reject) => {
        const startTime = new Date()
        Vue.prototype.$http.patch(getters._genApiUrl(data.path), data.body, {
          headers: {
            Authorization: getters.authToken,
            ...data.headers
          },
          params: data.params
        }).then((result) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(data.path), urlCreation: data, rawData: result })
          resolve(result)
        }).catch((g) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(data.path), urlCreation: data, error: g })
          reject(g)
        })
      })
    },
    getGuild ({ commit, dispatch, getters }, data = { id: '', params: {} }) {
      data = { id: (data.id ? data.id : ''), params: (data.params ? data.params : {}) }
      return new Promise((resolve, reject) => {
        const startTime = new Date()
        Vue.prototype.$http.get(getters._genApiUrl(`guild/${data.id}`), {
          headers: {
            Authorization: getters.authToken
          },
          params: data.params
        }).then((result) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(`guild/${data.id}`), urlCreation: data, rawData: result })
          resolve(result)
        }).catch(async (g) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(`guild/${data.id}`), urlCreation: data, error: g })
          reject(g)
        })
      })
    },
    getGuilds ({ commit, dispatch, getters }, data = {}) {
      return new Promise((resolve, reject) => {
        const startTime = new Date()
        Vue.prototype.$http.get(getters._genApiUrl('guild/'), {
          headers: {
            Authorization: getters.authToken
          },
          params: data
        }).then((result) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('guild/'), urlCreation: data, rawData: result })
          if (result.data && result.data.code === 200 && !result.data.message) {
            commit('setGuilds', result.data.guildsData)
            resolve(result.data.guildsData)
          } else {
            commit('setGuilds', [])
            resolve([])
          }
        }).catch((g) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl(data.path), urlCreation: data, error: g })
          commit('setGuilds', [])
          resolve([])
        })
      })
    },
    getUser ({ commit, dispatch, getters }) {
      const startTime = new Date()
      Vue.prototype.$http.get(getters._genApiUrl('user/'), {
        headers: {
          Authorization: getters.authToken
        }
      }).then((result) => {
        commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('user/'), urlCreation: null, rawData: result })
        if (result.data && result.data.code === 200 && !result.data.message) {
          result.data.userData.color = ('#' + result.data.userData.color)
          commit('user', result.data.userData)
          commit('isLogged', true)
        } else {
          commit('authToken', '')
          commit('user', null)
          commit('isLogged', false)
        }
        commit('LoadStatus', true)
      }).catch((g) => {
        commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('user/'), urlCreation: null, error: g })
        commit('authToken', '')
        commit('user', null)
        commit('isLogged', false)
        commit('LoadStatus', true)
      })
    },
    getStats ({ commit, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        const startTime = new Date()
        Vue.prototype.$http.get(getters._genApiUrl('plugin/shards/stats')).then((result) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('plugin/shards/stats'), urlCreation: null, rawData: result })
          resolve(result)
        }).catch((g) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('plugin/shards/stats'), urlCreation: null, error: g })
          resolve(g)
        })
      })
    },
    sleep ({ commit, dispatch, getters }, milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    },
    getNotification ({ commit, dispatch, getters }) {
      const startTime = new Date()
      Vue.prototype.$http.get(getters._genApiUrl('plugin/notifications'), {
        headers: {
          Authorization: getters.authToken
        }
      }).then((result) => {
        commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('system/notifications'), urlCreation: null, rawData: result })
        commit('notifications', result.data.data.notifications)
      }).catch((g) => {
        console.error(g)
        commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('system/notifications'), urlCreation: null, error: g })
      })
    },
    async openDebugConsole ({ commit, dispatch, getters }) {
      if (!getters.admin.showButton) return
      const menu = Vue.prototype.$swal.mixin({
        input: 'text',
        confirmButtonText: 'Next &rarr;',
        showCancelButton: true,
        reverseButtons: true,
        progressSteps: [1, 2]
      })
      let value1 = null
      await menu.fire({
        title: 'Enter code.',
        text: 'Please enter a code or function'
      }).then((result) => {
        if (result.value) value1 = result.value
      })
      if (value1) {
        await menu.fire({
          title: 'Enter value',
          text: 'Please enter a value or params'
        }).then((result) => {
          if (!result?.value || !value1) return
          if (value1 === 'event') {
            if (result.value === 'logout') {
              dispatch('logout').then((g) => {
                console.warn('LOGGED OUT FULLY')
              }).catch((g) => {
                console.warn(g, 'Logout Error')
                commit('authToken', '')
                commit('isLoggingOut', false)
                console.warn('REMOVED CLIENT TOKEN FROM CLIENT. CLIENT TOKEN MIGHT NOT HAVE BEEN REMOVED FROM BACKEND.')
              })
            } else if (result.value === 'login') {
              window.location = `${getters.admin.discord.url}oauth2/authorize?client_id=604170962178408469&redirect_uri=${encodeURIComponent((process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://api.gracebot.net/') + 'v1/auth/callback')}&response_type=code&scope=identify+guilds+email&prompt=none`
            } else if (result.value === 'userbackground') {
              dispatch('uploadUserBackground')
            } else if (result.value === 'userDataDump') {
              dispatch('userDataDump')
            } else if (result.value === 'rick') {
              commit('updateAdminVar', { var: 'rick', value: getters.admin.rick !== true })
            } else if (result.value === 'promo') {
              commit('updateAdminVar', { var: 'promo', value: !getters.admin.promo })
            } else if (result.value === 'topgg') {
              commit('updateAdminVar', { var: 'topggRestrictions', value: !getters.admin.topggRestrictions })
            }
          } else if (value1 === 'log') {
            if (result.value === 'api') {
              Vue.prototype.$buefy.snackbar.open({
                message: 'API Logs Outputed to console',
                type: 'is-warning',
                duration: 15000
              })
              console.warn(`------ API LOGS OUTPUT START ------\n\n LOGS:\n${JSON.stringify(getters.api.apiTimesData)}\n\n API INFO:\n${JSON.stringify({ ...getters.api, apiTimesData: 'Check Above', build: getters.build, isLoaded: getters.isLoaded, isLoading: getters.isLoading, isLoggingOut: getters.isLoggingOut, UI: getters.ui, admin: { ...getters.admin, systemIds: null } })}\n\n User Info:\n${JSON.stringify({ ...getters.user, background: null })}\n\n ------ API LOGS OUTPUT ENDED ------`)
            }
          } else if (value1 === 'version') {
            if (isNaN(Number(result.value))) return
            commit('updateAPI', { var: 'version', value: Number(result.value) })
          } else if (value1 === 'debug') {
            if (result.value === 'exit' || result.value === 'end' || result.value === 'clear') {
              localStorage.setItem('debugSYS', 0)
              window.location.reload()
            } else if (result.value === 'ads') {
              console.log(getters.advertisement.active, 'before')
              commit('updateAdvertisement', { var: 'active', value: !getters.advertisement.active })
              console.log(getters.advertisement.active, 'after')
            } else if (result.value === 'token') {
              Vue.prototype.$buefy.snackbar.open({
                message: 'Done. Check console.',
                type: 'is-warning',
                duration: 15000
              })
              console.error('------ API TOKEN OUTPUT START ------')
              console.warn("Warning, This token below is very dangours. Do not give it out to people you don't know!")
              console.info(`Token: ${getters.authToken}`)
              console.warn("Warning, This token below is very dangours. Do not give it out to people you don't know!")
              console.error('------ API TOKEN OUTPUT ENDED ------')
            }
          }
        })
      }
    },
    userDataDump ({ commit, dispatch, getters }) {
      if (!getters.user || !getters.isLogged) return
      if (!getters.user.canDataDump) return
      Vue.prototype.$swal.fire({
        title: 'User Data Dump',
        text: 'You will download any data we have about you in our DB.',
        footer: 'Only can do this once a day (24hrs)',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Download Data',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Vue.prototype.$swal.isLoading()
      }).then((result) => {
        if (result.value) {
          const startTime = new Date()
          Vue.prototype.$http.get(getters._genApiUrl('user/datadump/test'), {
            headers: {
              Authorization: getters.authToken
            },
            params: {}
          }).then((result) => {
            commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('user/datadump/test'), urlCreation: null, rawData: result })
            if (!result.data.canDo) throw new Error('Can\'t do data dump atm. Try again later!')
            window.open(getters._genApiUrl('user/datadump?token=' + getters.authToken))
            return true
          }).catch(error => {
            console.error(error)
            commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('user/datadump/test'), urlCreation: null, error: error })
            Vue.prototype.$buefy.snackbar.open({
              message: 'Oh no, could not download!',
              type: 'is-danger',
              duration: 15000
            })
          })
        }
      })
    },
    newContent ({ commit, dispatch, getters }) {
      Vue.prototype.$buefy.snackbar.open({
        message: '<b style="color: red;">A new version of the site is out! Please refresh your page!</b>',
        type: 'is-danger',
        indefinite: true,
        queue: false,
        actionText: null
      })
    },
    uploadUserBackground ({ commit, dispatch, getters }) {
      if (!getters.user || !getters.isLogged) return
      if (Number(getters.user.premium) <= 0) return
      Vue.prototype.$swal.fire({
        title: 'Upload User Background',
        footer: '16 MB max!',
        input: 'file',
        inputAttributes: {
          accept: ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'],
          'aria-label': 'Upload your profile picture'
        }
      }).then((result) => {
        if (!result.value) return
        const file = new Vue.prototype.$FormData()
        file.append('image', result.value, result.value.name)
        const startTime = new Date()
        Vue.prototype.$http.post(getters._genApiUrl('user/uploadbackground'), file, {
          headers: {
            Authorization: getters.authToken,
            'Content-Type': `multipart/form-data; boundary=${file._boundary}`
          },
          params: {}
        }).then(async (result) => {
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('user/uploadbackground'), urlCreation: null, rawData: result })
          Vue.prototype.$buefy.snackbar.open({
            message: 'New background image uploaded!',
            type: 'is-success',
            duration: 15000
          })
          await dispatch('sleep', 2000)
          dispatch('getUser')
        }).catch(async (g) => {
          console.error(g)
          commit('updateAPITimes', { startTime: startTime, endTime: new Date(), apiURL: getters._genApiUrl('user/uploadbackground'), urlCreation: null, error: g })
          Vue.prototype.$buefy.snackbar.open({
            message: 'Oh no, could not upload!',
            type: 'is-danger',
            duration: 15000
          })
          await dispatch('sleep', 2000)
          dispatch('getUser')
        })
      })
    }
  },
  getters: {
    api: state => state.api,
    user: state => state.user,
    isLogged: state => state.isLogged,
    isLoggingOut: state => state.isLoggingOut,
    authToken: state => state.authToken,
    notifications: state => state.notifications,
    _genApiUrl: state => (path, version = state.api.version) => {
      return state.api.path + `v${version}/` + path
    },
    _momentFormatUnix: state => (unixTime) => {
      return moment((unixTime * 1000)).format('MM/DD/YYYY [@] hh:mm A Z')
    },
    build: state => state.build,
    isLoaded: state => state.isLoaded,
    guilds: state => state.guilds,
    morning: state => state.morning,
    UI: state => state.UI,
    google: state => state.google,
    patreon: state => state.patreon,
    admin: state => state.admin,
    advertisement: state => state.advertisement
  }
})
