
<template>
      <b-navbar style="background-color: var(--main-color-bg) !important;font-size: 1.25rem" class="" fixed-top>
          <template slot="brand">
              <b-navbar-item class="mainTextColor" tag="router-link" :to="{ path: '/' }">
                  <img
                      src="https://cdn.gracebot.net/grace/logo.png"
                      alt="Logo"
                      class="min-height: 30px;"
                  >
                  Grace
              </b-navbar-item>
          </template>
          <template slot="start">
                <b-navbar-item tag="router-link" :to="{ path: '/support' }">
                    Support
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ path: '/invite' }">
                    Invite
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ path: '/vote?ref=gracebot-nav' }">
                    Vote
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ path: '/premium' }">
                    Premium
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
                  Dashboard
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ path: '/features' }">
                    Features
                </b-navbar-item>
                <b-navbar-dropdown :collapsible="true" class="" label="Docs">
                    <b-navbar-item tag="router-link" :to="{ path: '/docs' }">
                        Bot Docs
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ path: '/docs/website' }">
                        Website Docs
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ path: '/docs/hub' }">
                        Hub Docs
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ path: '/docs/api' }">
                        API Docs
                    </b-navbar-item>
                </b-navbar-dropdown>
                <b-navbar-dropdown :collapsible="true" class="" label="Other">
                    <!-- <b-navbar-item tag="router-link" :to="{ path: '/status' }">
                        Status
                    </b-navbar-item> -->
                    <b-navbar-item tag="router-link" :to="{ path: '/stats' }">
                        Bot Stats
                    </b-navbar-item>
                    <b-navbar-item v-if="$store.getters.admin.promo" tag="router-link" :to="{ path: '/promo' }">
                        Redeem Code
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ path: '/lvlcal' }">
                        Level Calculator
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ path: '/commands' }">
                        Commands
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ path: '/trello' }">
                        Trello
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" :to="{ path: '/report' }">
                        Report
                    </b-navbar-item>
                </b-navbar-dropdown>
          </template>

        <template slot="end">
            <b-navbar-dropdown :collapsible="true" right position="is-bottom-left" :arrowless="true" class="" aria-role="list">
              <template slot="label">
                <b-icon icon="bell" :class="{'bellimp': (this.Nots.filter(g => g.important).length > 0)}" slot="trigger" :pack="(this.Nots.length > 0 ? `fas` : `far`)" size="is-small"></b-icon>
              </template>

                <Notification style="width: 400px !important" v-for="notification in this.Nots" :key="notification.id" :notification="notification" />
                <div v-if="this.Nots.length <= 0" style="width: max-content; padding: 10px 20px 10px;" class="has-text-centered">
                  <h1 class="title is-5 noselect has-text-centered">No New Notification!</h1>
                  <b-button :loading="this.isRefreshNot" @click="checkForUpdateNot()" type="is-info">Refresh</b-button>
                </div>
            </b-navbar-dropdown>
            <b-navbar-dropdown :collapsible="false" v-if="this.$store.getters.isLogged" right position="is-bottom-left" class="">
                <template slot="label">
                    <img class="icon-img has-shadow" style="max-height: 36px; margin-right: 5px;" :src="($store.getters.user.avatar ? 'https://cdn.discordapp.com/avatars/' + this.$store.getters.user.id + '/' + this.$store.getters.user.avatar + '.' + (this.$store.getters.user.avatar.includes('a_') ? 'gif' : 'webp') + '?size=128' : 'this.src=\'https://cdn.discordapp.com/embed/avatars/' + (Math.floor(1000 + Math.random() * (this.$store.getters.user.discriminator ? this.$store.getters.user.discriminator : 9000)) % 5) + '.png?size=128\';')" :onerror="'this.src=\'https://cdn.discordapp.com/embed/avatars/' + (Math.floor(1000 + Math.random() * (this.$store.getters.user.discriminator ? this.$store.getters.user.discriminator : 9000)) % 5) + '.png?size=128\';'">
                    {{ (this.$store.getters.admin.streamerMode ? 'Hidden' : this.$store.getters.user.username) }}
                </template>
                <b-navbar-item tag="div" class="has-text-centered" style="display: block;">
                    <span style="font-size: 18px; font-weight: bold;" class="has-text-warning has-text-centered"><b-icon pack="fas" icon="coins" type="is-warning" size="is-medium"></b-icon> {{ this.$store.getters.user.coins }}</span>
                </b-navbar-item>
                <hr class="dropdown-divider" style="background-color: var(--main-color-bg-1);">
                <b-navbar-item tag="router-link" :to="{ path: `/profile/${this.$store.getters.user.id}` }">
                    Profile
                </b-navbar-item>
                <b-navbar-item v-if="Number(this.$store.getters.user.premium) >= 1" @click="$store.dispatch('uploadUserBackground')" >
                    Upload User Background
                </b-navbar-item>
                <b-navbar-item :title="!this.$store.getters.user.canDataDump ? 'Please wait another 24hrs before downloading another user data dump!': 'Download User Data Dump'" :class="{'is-disabled-noCheck': !this.$store.getters.user.canDataDump}" @click="$store.dispatch('userDataDump')" >
                    User Data Dump
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
                    Dashboard
                </b-navbar-item>
                <b-navbar-item v-if='$store.getters.admin.promo' tag="router-link" :to="{ path: '/promo' }">
                    Redeem Code
                </b-navbar-item>
                <b-navbar-item class="has-text-danger" tag="router-link" :to="{ path: '/logout' }">
                    Logout
                </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item v-else>
                <b-button type="is-success" icon-pack="fab" icon-left="discord" tag="router-link" :to="{ path: '/login' }"><strong>Login</strong></b-button>
            </b-navbar-item>
        </template>
      </b-navbar>
</template>

<script>
  import Notification from './Notification.vue'
  export default {
    name: 'NavBar',
    props: {},
    data () {
      return {
        seenIds: [],
        Nots: [],
        isRefreshNot: false
      }
    },
    watch: {
      async '$store.getters.notifications' (load) {
        if (this.seenIds.length <= 0) this.seenIds = JSON.parse(this.$localStorage.get('notificationsClosed') || '[]')
        this.updateNots((this.$store.getters.notifications || []).filter(g => g.mode === this.$store.getters.build || g.mode === 'any').filter(g => { return !this.seenIds.includes(`${('not-' + g.id)}`) }))
      }
    },
    mounted () {
    },
    components: {
      Notification
    },
    methods: {
      async checkForUpdateNot () {
        this.isRefreshNot = true
        await this.$store.dispatch('getNotification')
        await this.$store.dispatch('sleep', 1000)
        this.isRefreshNot = false
      },
      updateNots (newNots) {
        this.Nots = newNots || []
      },
      closedNote (not) {
        const nots = JSON.parse(this.$localStorage.get('notificationsClosed') || '[]')
        if (nots.includes(`${not}`)) return
        nots.push(`${not}`)
        this.$localStorage.set('notificationsClosed', JSON.stringify(nots))
        this.seenIds = nots || []
        this.updateNots((this.$store.getters.notifications || []).filter(g => g.mode === this.$store.getters.build || g.mode === 'any').filter(g => { return !this.seenIds.includes(`${('not-' + g.id)}`) }))
      }
    }
  }
</script>

<style>
#notific > .dropdown-menu {
    width: 400px;
}
.navbar.has-shadow {
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.navbar-item, .navbar-link {
  color: #fafafa !important;
}

.navbar-dropdown {
  background-color: var(--main-color-bg-1) !important;
  box-shadow: 0 1rem 2rem rgba(0,0,0,.1)!important;
  z-index: 2;
  color: #7957d5 !important;
  border-top: 2px solid transparent !important;
}

.navbar-menu {
    background-color: var(--main-color-bg) !important;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  background-color: var(--main-color-bg) !important;
  color: #7957d5 !important;
}

.navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: var(--main-color-bg) !important;
  color: #7957d5 !important;
}

.dropdown-content {
    background-color: var(--main-color-bg) !important;
}

a.dropdown-item, .dropdown .dropdown-menu .has-link a, button.dropdown-item {
    color: white;
}

a.dropdown-item:focus, a.dropdown-item:focus-within, a.dropdown-item:hover, a.dropdown-item.is-active, .dropdown-link:focus, .dropdown-link:focus-within, .dropdown-link:hover, .dropdown-link.is-active {
    background-color: var(--main-color-bg) !important;
  color: #7957d5 !important;
}
    .navbar-item, .navbar-link {
        transition: .2s !important;
    }
</style>

<style scoped>

    .message {
      margin-bottom: .5rem !important;
    }

    .has-shadow {
        box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
    }
    .icon-img {
        /* float: left; */
        border-radius: 100px;
        border-color: var(--main-color-bg-3);
        border-width: 1px;
        border-radius: 100px;
        border-style: solid;
        background-color: var(--main-color-bg) !important;
    }

    .navbar-item.is-active * .icon-img {
        border-color: #7957d5  !important;
    }
    .navbar-item:hover * .icon-img {
        border-color: #7957d5 !important;
    }

    #notificationDiv:not(:last-child) {
        margin-bottom: 0.5rem;
    }
    a, .navbar-item, .navbar-link {
        transition: .2s !important;
    }
    .bellimp {
        color: lightsalmon;
        /* animation: shake 1.5s;
        animation-iteration-count: infinite; */
    }

    /* .bellimp:hover {
        animation: unset;
    }

    @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
    } */
</style>
